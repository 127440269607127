import styled from 'styled-components';

export const StyledDummyContainer = styled.div`
    margin-bottom: 80px;
    @media (max-width: 768px) {
        margin-bottom: 32px;
    }
`;

export const StyledInsuranceContainer = styled.div``;

export const StyledNoPolicyText = styled.div(
    (p) => `
    text-align:center;
    font-weight:bold;
    margin-top: 8px;
    font-size: ${p.theme.fontSizes.mediumFont};
    color: ${p.theme.colors.mutedBG};
    @media (max-width: 768px) {
        padding: 10px 20px;
        line-height: 20px;
    }
`
);

export const StyledSubtitleSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 28px;
    gap: 4px;
    @media (max-width: 768px) {
        flex-direction: column;
        max-width: 80%;
        margin-top: 4px;
        margin-bottom: 16px;
    }
`;

export const StyledEnrolmentHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`;

export const StyledResponsiveText = styled.span<{
    $color: string;
    $fontDesktop: string;
    $fontMobile: string;
    $fontWeight?: string;
}>`
    color: ${(p) => p.$color};
    font-size: ${(p) => p.$fontDesktop};
    line-height: 20px;
    font-family: 'Work Sans';
    font-weight: ${(p) => p.$fontWeight || '500'};
    display: flex;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 768px) {
        font-size: ${(p) => p.$fontMobile};
        font-weight: 400;
    }
`;

export const StyledSendIcon = styled.img``;

export const StyledEnrolmentCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
`;

export const StyledWarningWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyleAlertWarning = styled.div`
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`;
