import React from 'react';
import {
    StyledDummyContainer,
    StyledEnrolmentCardsContainer,
    StyledEnrolmentHeaderContainer,
    StyledInsuranceContainer,
    StyledResponsiveText,
    StyledSubtitleSection
} from './styles';
import theme from '../../../theme';
import { IDummyPolicySectionProps } from './types';
import EnrolMembersCard from '../EnrolMembersCard';
import {
    formatAmount,
    getAvailableTopup,
    getDaysLeft,
    getSumInsured,
    insuranceCardDateFormat
} from '../../../utils/common';
import { calculateAPAmount } from '../../pages/SPAContainer/pages/DashboardPage/utils';
import { IPolicy } from '../../../redux/slices/PolicyListSlice/types';

const DummyPolicySection: React.FC<IDummyPolicySectionProps> = (props) => {
    const {
        policyList,
        handleCardClick,
        totalSteps,
        superTopUpPolicy,
        checkShowSuperTopUpCard,
        getEnrolledGMCPolicies,
        checkSuperTopUpAvailable,
        isSuperTopupPurchased
    } = props;
    return (
        <StyledDummyContainer>
            <StyledEnrolmentHeaderContainer>
                <StyledResponsiveText
                    $color={theme.colors.beyondGrey}
                    $fontDesktop={theme.fontSizes.title}
                    $fontMobile={theme.fontSizes.heading}
                >
                    For Enrolment Only
                </StyledResponsiveText>
                <StyledSubtitleSection>
                    <StyledResponsiveText
                        $color={theme.colors.beyondGrey}
                        $fontDesktop={theme.fontSizes.body}
                        $fontMobile={theme.fontSizes.subHeading}
                        $fontWeight='300'
                    >
                        Update members for your upcoming insurance policy
                    </StyledResponsiveText>
                    <StyledResponsiveText
                        $color={theme.colors.error}
                        $fontDesktop={theme.fontSizes.body}
                        $fontMobile={theme.fontSizes.subHeading}
                        $fontWeight='300'
                    >
                        Note: This won&apos;t affect your current policy.
                    </StyledResponsiveText>
                </StyledSubtitleSection>
            </StyledEnrolmentHeaderContainer>
            <StyledEnrolmentCardsContainer>
                {policyList?.map((policy, index) => (
                    <StyledInsuranceContainer key={`EnrolPending-${index}`}>
                        <EnrolMembersCard
                            policyId={policy.policyId}
                            nickName={policy.nickName}
                            onClick={() => handleCardClick(policy)}
                            insuranceCategory={policy?.policyType?.toUpperCase()}
                            insuranceName={policy.policyName}
                            policyImage={policy.policyImage}
                            isEnrolPending={!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')}
                            sumInsured={formatAmount(policy.sumInsured)}
                            dependentsInsured={policy.familyStruture}
                            enrolmentDueDate={
                                policy?.enrolmentDueDate
                                    ? insuranceCardDateFormat(policy.enrolmentDueDate)
                                    : 'N/A'
                            }
                            policyEndDate={
                                policy?.policyEndDate
                                    ? insuranceCardDateFormat(policy?.policyEndDate?._seconds)
                                    : 'N/A'
                            }
                            annualPremium={calculateAPAmount(
                                superTopUpPolicy?.annualPremium ?? 0,
                                superTopUpPolicy?.digitPremiumWithoutGST ?? 0,
                                superTopUpPolicy?.gst ?? 0
                            )}
                            daysLeft={getDaysLeft(policy.enrolmentDueDate)}
                            dependents={policy.dependents}
                            availableTopUp={getAvailableTopup(policy.availableTopUp)}
                            topUpAdded={getSumInsured(policy.topUpAdded, '')}
                            superTopupStatus={checkSuperTopUpAvailable}
                            SI={policy.sumInsured}
                            enrolmentStatus={policy?.enrolmentStatus as string}
                            currentStep={index + 1}
                            addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                            isSuperTopupPurchased={isSuperTopupPurchased}
                        />
                    </StyledInsuranceContainer>
                ))}
                {checkShowSuperTopUpCard && superTopUpPolicy?.enrolmentStatus === 'ENROLED' ? (
                    <StyledInsuranceContainer key={`EnrolPending-${4}`}>
                        <EnrolMembersCard
                            policyId={superTopUpPolicy?.policyId}
                            onClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                            insuranceCategory={superTopUpPolicy?.policyType?.toUpperCase() ?? ''}
                            insuranceName={superTopUpPolicy?.policyName ?? ''}
                            policyImage={superTopUpPolicy?.policyImage ?? ''}
                            isEnrolPending={
                                !(getDaysLeft(superTopUpPolicy?.enrolmentDueDate ?? 0) === 'OVERDUE')
                            }
                            sumInsured={formatAmount(superTopUpPolicy?.sumInsured)}
                            dependentsInsured={''}
                            enrolmentDueDate={
                                getEnrolledGMCPolicies[0]?.enrolmentDueDate
                                    ? insuranceCardDateFormat(getEnrolledGMCPolicies[0].enrolmentDueDate)
                                    : 'N/A'
                            }
                            policyEndDate={
                                superTopUpPolicy?.policyEndDate
                                    ? insuranceCardDateFormat(superTopUpPolicy?.policyEndDate?._seconds)
                                    : 'N/A'
                            }
                            annualPremium={getEnrolledGMCPolicies[0]?.annualPremium}
                            daysLeft={getDaysLeft(getEnrolledGMCPolicies[0]?.enrolmentDueDate ?? 0)}
                            dependents={getEnrolledGMCPolicies[0]?.dependents ?? []}
                            availableTopUp={getAvailableTopup(
                                getEnrolledGMCPolicies[0]?.availableTopUp ?? 0
                            )}
                            topUpAdded={getSumInsured(getEnrolledGMCPolicies[0]?.topUpAdded ?? 0, '')}
                            superTopupStatus={checkSuperTopUpAvailable}
                            enrolmentStatus={superTopUpPolicy?.enrolmentStatus ?? 'PENDING'}
                            currentStep={totalSteps - 1}
                            insurerName={superTopUpPolicy.insurerName}
                            addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                        />
                    </StyledInsuranceContainer>
                ) : null}
            </StyledEnrolmentCardsContainer>
        </StyledDummyContainer>
    );
};

export default DummyPolicySection;
